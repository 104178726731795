import { createCanvas, imageDataToHex } from './helpers.js';

const getYoutubeCanvas = () => {
  const canvasSize = [1, 49];
  const canvas = createCanvas(...canvasSize);

  const context = canvas.getContext('2d');
  context.clearRect(0.0, 0.0, 1.0, 49.0);

  const gradient = context.createLinearGradient(0.0, 2.0, 0.0, 49.0);
  gradient.addColorStop(0.0, 'rgba(0, 0, 0, 0)');
  gradient.addColorStop(0.1063829787, 'rgba(0, 0, 0, 0.3)');
  gradient.addColorStop(1.0, 'rgba(0, 0, 0, 0.68)');
  context.fillStyle = gradient;
  context.fillRect(0.0, 2.0, 1.0, 47.0);

  return imageDataToHex(context.getImageData(0, 0, ...canvasSize));
};

const getGoogleCanvas = () => {
  const canvasSize = [400, 200];
  const canvas = createCanvas(...canvasSize);

  const context = canvas.getContext('2d');
  context.lineWidth = 38.0;
  context.shadowBlur = 43.0;
  context.shadowColor = '#be7';

  const radialGradient = context.createRadialGradient(200.0, 200.0, 0.0, 270.0, 140.0, 200.0);
  radialGradient.addColorStop(0.25, '#dbe');
  radialGradient.addColorStop(0.5, '#317');
  radialGradient.addColorStop(1.0, '#67e');
  context.strokeStyle = radialGradient;
  context.beginPath();
  context.arc(313.0, 0.0, 127.0, 247.0, 247.5, false);
  context.stroke();
  context.lineWidth = 5.0;
  context.shadowBlur = 38.0;
  context.shadowColor = '#b66';

  const linearGradient = context.createLinearGradient(0.0, 0.0, 400.0, 0.0);
  linearGradient.addColorStop(0.0, '#88f');
  linearGradient.addColorStop(0.5, '#737');
  linearGradient.addColorStop(1.0, '#6dc');
  context.strokeStyle = linearGradient;
  context.font = '88px X';
  context.strokeText('GDp}+7k<', 57.0, 100.0);
  context.lineWidth = 25.0;
  context.shadowBlur = 22.0;
  context.shadowColor = '#2af';
  context.globalCompositeOperation = 'multiply';

  const radialGradient2 = context.createRadialGradient(200.0, 200.0, 0.0, 270.0, 140.0, 200.0);
  radialGradient2.addColorStop(0.25, '#ee4');
  radialGradient2.addColorStop(0.5, '#caf');
  radialGradient2.addColorStop(1.0, '#546');
  context.strokeStyle = radialGradient2;
  context.beginPath();
  context.arc(113.0, 50.0, 127.0, 0.0, 0.5, false);
  context.stroke();
  context.globalCompositeOperation = 'color-dodge';
  context.fillText('😊', 80.0, 100.0);

  return imageDataToHex(context.getImageData(0, 0, ...canvasSize));
};

const getCloudflareCanvas = () => {
  const canvasSize = [125, 125];
  const canvas = createCanvas(...canvasSize);

  const context = canvas.getContext('2d');

  const radialGradient = context.createRadialGradient(33.0, 18.0, 8.0, 42.0, 10.0, 226.0);
  radialGradient.addColorStop(0.0, '#809900');
  radialGradient.addColorStop(1.0, '#404041');
  context.fillStyle = radialGradient;

  context.shadowBlur = 11.0;
  context.shadowColor = '#F38020';
  context.beginPath();
  context.moveTo(10.0, 14.0);
  context.quadraticCurveTo(93.0, 48.0, 115.0, 111.0);
  context.stroke();
  context.fill('nonzero');
  context.shadowBlur = 0.0;

  const radialGradient2 = context.createRadialGradient(77.0, 98.0, 2.0, 27.0, 30.0, 206.0);
  radialGradient2.addColorStop(0.0, '#809900');
  radialGradient2.addColorStop(1.0, '#404041');
  context.fillStyle = radialGradient2;

  context.beginPath();
  context.ellipse(60.0, 58.0, 32.0, 29.0, 1.444170596, 0.5401125109, 4.0522339847, false);
  context.stroke();
  context.fill('nonzero');
  context.shadowBlur = 0.0;

  const radialGradient3 = context.createRadialGradient(108.0, 12.0, 10.0, 65.0, 118.0, 169.0);
  radialGradient3.addColorStop(0.0, '#1AB399');
  radialGradient3.addColorStop(1.0, '#E666B3');
  context.fillStyle = radialGradient3;

  context.shadowBlur = 16.0;
  context.shadowColor = '#809980';
  context.font = '27.77777777777778px aanotafontaa';
  context.fillText('Ry', 13.0, 67.25);
  context.shadowBlur = 0.0;

  const radialGradient4 = context.createRadialGradient(46.0, 47.0, 0.0, 101.0, 108.0, 207.0);
  radialGradient4.addColorStop(0.0, '#4DB380');
  radialGradient4.addColorStop(1.0, '#FF4D4D');
  context.fillStyle = radialGradient4;

  context.shadowBlur = 3.0;
  context.shadowColor = '#FF6633';
  context.beginPath();
  context.moveTo(57.0, 5.0);
  context.bezierCurveTo(54.0, 90.0, 32.0, 74.0, 67.0, 120.0);
  context.stroke();
  context.fill('nonzero');
  context.shadowBlur = 0.0;

  const radialGradient5 = context.createRadialGradient(119.0, 123.0, 3.0, 109.0, 90.0, 137.0);
  radialGradient5.addColorStop(0.0, '#E6B333');
  radialGradient5.addColorStop(1.0, '#3366E6');
  context.fillStyle = radialGradient5;

  context.shadowBlur = 4.0;
  context.shadowColor = '#B3B31A';
  context.beginPath();
  context.moveTo(79.0, 0.0);
  context.bezierCurveTo(1.0, 49.0, 103.0, 67.0, 45.0, 125.0);
  context.stroke();
  context.fill('nonzero');
  context.shadowBlur = 0.0;

  const radialGradient6 = context.createRadialGradient(34.0, 47.0, 1.0, 37.0, 59.0, 245.0);
  radialGradient6.addColorStop(0.0, '#809900');
  radialGradient6.addColorStop(1.0, '#404041');
  context.fillStyle = radialGradient6;

  context.beginPath();
  context.ellipse(57.0, 57.0, 15.0, 8.0, 1.2273132071, 4.1926143019, 2.885353923, false);
  context.stroke();
  context.fill('nonzero');
  context.shadowBlur = 0.0;
  context.shadowBlur = 14.0;
  context.shadowColor = '#809900';
  context.font = '11.904761904761905px aanotafontaa';
  context.strokeText('@H1', 30.0, 74.25);
  context.shadowBlur = 0.0;

  return imageDataToHex(context.getImageData(0, 0, ...canvasSize));
};

const getAmazonCanvas = () => {
  const canvasSize = [150, 60];
  const canvas = createCanvas(...canvasSize);

  const context = canvas.getContext('2d');
  context.rect(0.0, 0.0, 10.0, 10.0);
  context.rect(2.0, 2.0, 6.0, 6.0);
  context.textBaseline = 'alphabetic';
  context.fillStyle = '#f60';
  context.fillRect(125.0, 1.0, 62.0, 20.0);
  context.fillStyle = '#069';
  context.font = '8pt Arial';
  context.fillText('Cwm fjordbank glyphs vext quiz,', 2.0, 15.0);
  context.fillStyle = 'rgba(102, 204, 0, 0.2)';
  context.font = '11pt Arial';
  context.fillText('Cwm fjordbank glyphs vext quiz,', 4.0, 45.0);
  context.globalCompositeOperation = 'multiply';
  context.fillStyle = 'rgb(255,0,255)';
  context.beginPath();
  context.arc(20.0, 20.0, 20.0, 0.0, 6.2831853072, true);
  context.closePath();
  context.fill('nonzero');
  context.fillStyle = 'rgb(0,255,255)';
  context.beginPath();
  context.arc(50.0, 20.0, 20.0, 0.0, 6.2831853072, true);
  context.closePath();
  context.fill('nonzero');
  context.fillStyle = 'rgb(255,255,0)';
  context.beginPath();
  context.arc(35.0, 40.0, 20.0, 0.0, 6.2831853072, true);
  context.closePath();
  context.fill('nonzero');
  context.fillStyle = 'rgb(255,0,255)';
  context.arc(20.0, 25.0, 10.0, 0.0, 6.2831853072, true);
  context.arc(20.0, 25.0, 20.0, 0.0, 6.2831853072, true);
  context.fill('evenodd');

  const linearGradient = context.createLinearGradient(40.0, 50.0, 60.0, 78.0);
  linearGradient.addColorStop(0.0, 'blue');
  linearGradient.addColorStop(0.5, 'red');
  linearGradient.addColorStop(1.0, 'white');
  context.fillStyle = linearGradient;
  context.beginPath();
  context.arc(70.0, 50.0, 10.0, 0.0, 6.2831853072, true);
  context.closePath();
  context.fill('nonzero');
  context.font = '10pt dfgstg';
  context.strokeText('-1.4214488238747245', 4.0, 30.0);
  context.fillText('-0.5753861119575491', 4.0, 40.0);
  context.fillText('0.8178819121159085', 4.0, 50.0);
  context.beginPath();
  context.moveTo(25.0, 0.0);
  context.quadraticCurveTo(1.0, 1.0, 1.0, 5.0);
  context.quadraticCurveTo(1.0, 76.0, 26.0, 10.0);
  context.quadraticCurveTo(26.0, 96.0, 6.0, 12.0);
  context.quadraticCurveTo(60.0, 96.0, 41.0, 10.0);
  context.quadraticCurveTo(121.0, 86.0, 101.0, 7.0);
  context.quadraticCurveTo(121.0, 1.0, 56.0, 1.0);
  context.stroke();
  context.globalCompositeOperation = 'difference';
  context.fillStyle = 'rgb(255,0,255)';
  context.beginPath();
  context.arc(80.0, 20.0, 20.0, 0.0, 6.2831853072, true);
  context.closePath();
  context.fill('nonzero');
  context.fillStyle = 'rgb(0,255,255)';
  context.beginPath();
  context.arc(110.0, 20.0, 20.0, 0.0, 6.2831853072, true);
  context.closePath();
  context.fill('nonzero');
  context.fillStyle = 'rgb(255,255,0)';
  context.beginPath();
  context.arc(95.0, 40.0, 20.0, 0.0, 6.2831853072, true);
  context.closePath();
  context.fill('nonzero');
  context.fillStyle = 'rgb(255,0,255)';
  context.fillStyle = '#808080';
  context.font = '8pt Arial';
  context.fillText('NOT AVAILABLE', 2.0, 30.0);

  return imageDataToHex(context.getImageData(0, 0, ...canvasSize));
};

const getLinkedinCanvas = () => {
  const canvasSize = [600, 160];
  const canvas = createCanvas(...canvasSize);

  const context = canvas.getContext('2d');
  context.rect(1.0, 1.0, 11.0, 11.0);
  context.rect(3.0, 3.0, 7.0, 7.0);
  context.textBaseline = 'alphabetic';
  context.fillStyle = '#f60';
  context.fillRect(125.0, 1.0, 62.0, 20.0);
  context.fillStyle = '#069';
  context.font = '11pt Arial';
  context.fillText('Cwm fjordbank glyphs vext quiz, 😄 ', 2.0, 15.0);
  context.fillStyle = 'rgba(102, 204, 0, 0.7)';
  context.font = '18pt Arial';
  context.fillText('Cwm fjordbank glyphs vext quiz, 😂 ', 4.0, 45.0);
  context.globalCompositeOperation = 'multiply';
  context.fillStyle = 'rgb(255,0,255)';
  context.beginPath();
  context.arc(50.0, 50.0, 50.0, 0.0, 6.2831853072, true);
  context.closePath();
  context.fill('nonzero');
  context.fillStyle = 'rgb(0,255,255)';
  context.beginPath();
  context.arc(100.0, 50.0, 50.0, 0.0, 6.2831853072, true);
  context.closePath();
  context.fill('nonzero');
  context.fillStyle = 'rgb(255,255,0)';
  context.beginPath();
  context.arc(75.0, 100.0, 50.0, 0.0, 6.2831853072, true);
  context.closePath();
  context.fill('nonzero');
  context.fillStyle = 'rgb(255,0,255)';
  context.arc(75.0, 75.0, 75.0, 0.0, 6.2831853072, true);
  context.arc(75.0, 75.0, 25.0, 0.0, 6.2831853072, true);
  context.fill('evenodd');

  // TODO: mb fix to `return imageDataToHex(context.getImageData(0, 0, ...canvasSize));`

  const str = canvas.toDataURL('image/png');
  let hex = '';

  for (let i = 0; i < str.length; i++) {
    const add = str.charCodeAt(i).toString(16);
    if (!add.length) {
      hex += '00';
    }

    if (add.length === 1) {
      hex += '0';
    }

    hex += add;
  }

  return hex;
};

const getPSCanvas = () => {
  const canvasSize = [250, 80];
  const canvas = createCanvas(...canvasSize);

  const context = canvas.getContext('2d');
  context.textBaseline = 'top';
  context.font = "15.5px 'Arial'";
  context.textBaseline = 'alphabetic';
  context.fillStyle = '#f60';
  context.fillRect(125.0, 1.0, 62.0, 20.0);
  context.fillStyle = '#069';
  context.fillText('SomeCanvasFingerPrint.65@345876', 2.0, 15.0);
  context.fillStyle = 'rgba(102, 204, 0, 0.7)';
  context.fillText('SomeCanvasFingerPrint.65@345876', 4.0, 17.0);
  context.fillStyle = 'rgb(74,135,179)';
  context.shadowBlur = 8.0;
  context.shadowColor = 'red';
  context.arc(40.0, 40.0, 25.0, 0.0, 6.2831853072, true);
  context.fill('nonzero');
  context.stroke();
  context.closePath();
  context.shadowBlur = 5.5;
  context.shadowColor = 'rgb(43,22,233)';
  context.beginPath();
  context.moveTo(67.0, -40.0);
  context.lineTo(200.0, 250.0);
  context.lineTo(100.0, 250.0);
  context.closePath();
  context.fillStyle = '#aecc71';
  context.fill('nonzero');
  context.shadowBlur = 0.0;
  context.beginPath();
  context.moveTo(88.0, 0.0);
  context.bezierCurveTo(200.0, 50.0, 400.0, 250.0, 500.0, 200.0);
  context.strokeStyle = 'black';
  context.lineWidth = 2.0;
  context.stroke();

  // TODO: mb fix to `return imageDataToHex(context.getImageData(0, 0, ...canvasSize));`

  const str = canvas.toDataURL('image/png');
  let hex = '';

  for (let i = 0; i < str.length; i++) {
    const add = str.charCodeAt(i).toString(16);
    if (!add.length) {
      hex += '00';
    }

    if (add.length === 1) {
      hex += '0';
    }

    hex += add;
  }

  return hex;
};

const getTikTokCanvas = () => {
  const canvasSize = [94, 31];
  const canvas = createCanvas(...canvasSize);

  const context = canvas.getContext('2d');

  const linearGradient = context.createLinearGradient(10.0, 0.0, 180.0, 1.0);
  linearGradient.addColorStop(0.0, 'red');
  linearGradient.addColorStop(0.1, 'white');
  linearGradient.addColorStop(0.2, 'blue');
  linearGradient.addColorStop(0.3, 'yellow');
  linearGradient.addColorStop(0.4, 'purple');
  linearGradient.addColorStop(0.7, 'orange');
  linearGradient.addColorStop(1.0, 'magenta');
  context.fillStyle = linearGradient;
  context.fillRect(0.0, 10.0, 100.0, 6.0);
  context.beginPath();

  const linearGradient2 = context.createLinearGradient(0.0, 0.0, 100.0, 100.0);
  linearGradient2.addColorStop(0.0, 'green');
  linearGradient2.addColorStop(0.5, 'yellow');
  linearGradient2.addColorStop(0.7, 'orange');
  linearGradient2.addColorStop(1.0, 'magenta');
  context.fillStyle = linearGradient2;

  context.arc(50.0, 10.0, 25.0, 0.0, 6.2831853072, false);
  context.stroke();
  context.fillStyle = 'rgba(150, 32, 170, .97)';
  context.font = '12px Sans';
  context.textBaseline = 'top';
  context.fillText('*+(}#?🐼 🎅', 18.0, 18.0);
  context.shadowBlur = 1.0;
  context.fillStyle = 'rgba(47, 211, 69, .99)';
  context.font = '14px Sans';
  context.textBaseline = 'top';
  context.fillText('🐼OynG@%tp$', 3.0, 3.0);
  context.beginPath();
  context.arc(30.0, 10.0, 20.0, 0.0, 6.2831853072, false);
  context.strokeStyle = 'rgba(255, 12, 220, 1)';
  context.stroke();

  return imageDataToHex(context.getImageData(0, 0, ...canvasSize));
};

const getWebglNoise = () => {
  const width = 50;
  const height = 50;

  let canvas;
  let ctx;
  let startTime;
  let durationArrays = -1;
  let durationProgram = -1;

  canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;

  ctx =
    canvas.getContext('webgl2') ||
    canvas.getContext('experimental-webgl2') ||
    canvas.getContext('webgl') ||
    canvas.getContext('experimental-webgl') ||
    canvas.getContext('moz-webgl');

  try {
    const f =
      'attribute vec2 attrVertex;varying vec2 varyinTexCoordinate;uniform vec2 uniformOffset;void main(){varyinTexCoordinate=attrVertex+uniformOffset;gl_Position=vec4(attrVertex,0,1);}';
    const g =
      'precision mediump float;varying vec2 varyinTexCoordinate;void main() {gl_FragColor=vec4(varyinTexCoordinate,0,1);}';

    const h = ctx.createBuffer();
    ctx.bindBuffer(ctx.ARRAY_BUFFER, h);

    const i = new Float32Array([-0.2, -0.9, 0, 0.4, -0.26, 0, 0, 0.7321, 0]);
    ctx.bufferData(ctx.ARRAY_BUFFER, i, ctx.STATIC_DRAW);

    h.itemSize = 3;
    h.numItems = 3;

    startTime = performance.now();
    const j = ctx.createProgram();
    const k = ctx.createShader(ctx.VERTEX_SHADER);

    ctx.shaderSource(k, f);
    ctx.compileShader(k);

    const l = ctx.createShader(ctx.FRAGMENT_SHADER);

    ctx.shaderSource(l, g);
    ctx.compileShader(l);
    ctx.attachShader(j, k);
    ctx.attachShader(j, l);
    ctx.linkProgram(j);
    ctx.useProgram(j);
    durationProgram = performance.now() - startTime;

    j.vertexPosAttrib = ctx.getAttribLocation(j, 'attrVertex');
    j.offsetUniform = ctx.getUniformLocation(j, 'uniformOffset');

    // TODO: mb fix to `ctx.enableVertexAttribArray(j.vertexPosAttrib);`
    ctx.enableVertexAttribArray(j.vertexPosArray);
    ctx.vertexAttribPointer(j.vertexPosAttrib, h.itemSize, ctx.FLOAT, false, 0, 0);
    ctx.uniform2f(j.offsetUniform, 1, 1);

    startTime = performance.now();
    ctx.drawArrays(ctx.TRIANGLE_STRIP, 0, h.numItems);
    durationArrays = performance.now() - startTime;
  } catch (err) {
    console.log(`Error while drawing webgl`);
  }

  const pixels = new Uint8Array(width * height * 4);
  ctx.readPixels(0, 0, width, height, ctx.RGBA, ctx.UNSIGNED_BYTE, pixels);

  /* TODO: mb fix to 
    ```
      const binaryString = String.fromCharCode.apply(null, pixels);
      return window.btoa(binaryString);
    ```
  */
  return window.btoa(pixels);
};

const getCanvasNoise = () => {
  const width = 50;
  const height = 50;

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  context.moveTo(0, 0);
  context.lineTo(50, 50);
  context.stroke();

  const pixels_img = context.getImageData(0, 0, width, height);
  const len = pixels_img.data.length;
  const pixels = new Uint8Array(len);

  for (let i = 0; i < len; ++i) {
    pixels[i] = pixels_img.data[i];
  }

  /* TODO: mb fix to 
    ```
      const binaryString = String.fromCharCode.apply(null, pixels_img.data);
      return window.btoa(binaryString);
    ```
  */
  return window.btoa(pixels);
};

export const getRenderingParams = () => {
  const canvasNoise = getCanvasNoise();
  const webglNoise = getWebglNoise();
  const youtubeCanvas = getYoutubeCanvas();
  const googleCanvas = getGoogleCanvas();
  const cloudflareCanvas = getCloudflareCanvas();
  const amazonCanvas = getAmazonCanvas();
  const linkedinCanvas = getLinkedinCanvas();
  const psCanvas = getPSCanvas();
  const tikTokCanvas = getTikTokCanvas();

  return {
    canvas: {
      noise: canvasNoise,
      youtubeCanvas,
      googleCanvas,
      cloudflareCanvas,
      amazonCanvas,
      linkedinCanvas,
      psCanvas,
      tikTokCanvas,
    },
    webgl: {
      noise: webglNoise,
    },
  };
};
