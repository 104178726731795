export const getStorageQuota = async () => {
  if (navigator.storage && navigator.storage.estimate) {
    try {
      const estimate = await navigator.storage.estimate();
      return {
        quota: estimate.quota,
        usage: estimate.usage,
      };
    } catch (error) {
      console.error('Error when retrieving storage information: ', error);
      return null;
    }
  } else {
    console.warn('The storage API is not supported');
    return null;
  }
};

export const getGlobalObjects = () => {
  const globalObjects = [];
  for (let key in window) {
    if (window.hasOwnProperty(key)) {
      globalObjects.push(key);
    }
  }
  return globalObjects;
};

export const getMediaCapabilities = () => {
  if (!navigator.mediaDevices || !navigator.mediaDevices.getSupportedConstraints) {
    console.warn('MediaDevices API is not supported');
    return null;
  }

  const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();

  const capabilities = {};
  for (const constraint in supportedConstraints) {
    capabilities[constraint] = supportedConstraints[constraint];
  }

  return capabilities;
};

export const getPerformanceData = () => {
  const performance = window.performance;
  const navigation = performance.navigation;
  const timing = performance.timing;
  const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;

  const navigationEntries = performance.getEntriesByType('navigation')[0];
  const paintEntries = performance.getEntriesByType('paint');

  return {
    commitLoadTime: timing.responseStart,
    connectionInfo: connection ? connection.effectiveType : 'unknown',
    finishDocumentLoadTime: timing.domContentLoadedEventEnd,
    finishLoadTime: timing.loadEventEnd,
    firstPaintAfterLoadTime: paintEntries.length > 0 ? paintEntries[0].startTime : 0,
    firstPaintTime: paintEntries.length > 0 ? paintEntries[0].startTime + timing.navigationStart : 0,
    navigationType: ['navigate', 'reload', 'back_forward', 'prerender'][navigation.type],
    npnNegotiatedProtocol: navigationEntries ? navigationEntries.nextHopProtocol : 'unknown',
    requestTime: timing.requestStart,
    startLoadTime: timing.fetchStart,
    wasAlternateProtocolAvailable: navigationEntries ? navigationEntries.alternateProtocolUsage === 'used' : false,
    wasFetchedViaSpdy: navigationEntries
      ? navigationEntries.nextHopProtocol.includes('h2') || navigationEntries.nextHopProtocol.includes('h3')
      : false,
    wasNpnNegotiated: navigationEntries ? navigationEntries.nextHopProtocol !== 'http/1.1' : false,
  };
};

const getScreenOrientation = () => {
  const orientation = screen.orientation || window.orientation;

  let angle, type;

  if (orientation && typeof orientation.angle !== 'undefined' && orientation.type) {
    angle = orientation.angle;
    type = orientation.type;
  } else if (typeof window.orientation !== 'undefined') {
    angle = window.orientation;
    type = angle === 0 || angle === 180 ? 'portrait-primary' : 'landscape-primary';
  } else {
    angle = 0;
    type = window.innerWidth > window.innerHeight ? 'landscape-primary' : 'portrait-primary';
  }

  return { angle, type };
};

export const getScreenResolution = () => {
  const screen = window.screen;

  return {
    availWidth: screen.availWidth,
    availHeight: screen.availHeight,
    width: screen.width,
    height: screen.height,
    colorDepth: screen.colorDepth,
    pixelDepth: screen.pixelDepth,
    devicePixelRatio: window.devicePixelRatio < 1 ? 1 : window.devicePixelRatio,
    innerWidth: window.innerWidth,
    outerWidth: window.outerWidth,
    innerHeight: window.innerHeight,
    outerHeight: window.outerHeight,
    orientation: getScreenOrientation(),
  };
};

export const getMemoryInfo = () => {
  if (performance && performance.memory) {
    return {
      jsHeapSizeLimit: performance.memory.jsHeapSizeLimit,
      totalJSHeapSize: performance.memory.totalJSHeapSize,
      usedJSHeapSize: performance.memory.usedJSHeapSize,
    };
  } else {
    console.warn('The memory API is not supported');
    return null;
  }
};

export const getComputedStyleProperties = () => {
  const div = document.createElement('div');
  document.body.appendChild(div);

  const computedStyle = window.getComputedStyle(div);

  const properties = [];
  for (let index = 0; index < computedStyle.length; index++) {
    properties.push(computedStyle[index]);
  }

  for (let prop in computedStyle) {
    if (!properties.includes(prop) && Number.isNaN(Number(prop))) {
      properties.push(prop);
    }
  }

  document.body.removeChild(div);

  return properties.sort();
};

export const getSystemInfo = () => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const systemColors = [
    'ActiveText',
    'ButtonFace',
    'ButtonText',
    'Canvas',
    'CanvasText',
    'Field',
    'FieldText',
    'GrayText',
    'Highlight',
    'HighlightText',
    'LinkText',
    'Mark',
    'MarkText',
    'VisitedText',
  ];
  const colors = systemColors.map((color) => {
    ctx.fillStyle = color;
    return ctx.fillStyle;
  });

  const systemFonts = ['serif', 'sans-serif', 'monospace', 'cursive', 'fantasy', 'system-ui'];
  const fonts = systemFonts.map((font) => {
    ctx.font = `12px ${font}`;
    return ctx.font;
  });

  return { colors, fonts };
};

export const checkFileAPIs = () => {
  const fileAPIs = [
    'FileReader',
    'FileList',
    'File',
    'FileSystemDirectoryHandle',
    'FileSystemFileHandle',
    'FileSystemHandle',
    'FileSystemWritableFileStream',
    'showOpenFilePicker',
    'showSaveFilePicker',
    'webkitRequestFileSystem',
    'webkitResolveLocalFileSystemURL',
  ];

  const supportedAPIs = fileAPIs.filter((api) => api in window);

  return supportedAPIs;
};

export const checkWebKitAPIs = () => {
  const webkitAPIs = [
    'onwebkitanimationend',
    'onwebkitanimationiteration',
    'onwebkitanimationstart',
    'onwebkittransitionend',
    'onwebkitfullscreenchange',
    'onwebkitfullscreenerror',
    'webkitMatchesSelector',
    'webkitRequestFullScreen',
    'webkitRequestFullscreen',
  ];

  const supportedAPIs = webkitAPIs.filter((api) => api in document.documentElement);

  return supportedAPIs;
};

export const checkFontRendering = () => {
  const fonts = ['Consolas', 'Windows sans-serif', 'Windows serif', 'mix Consolas'];

  const testString = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const testElement = document.createElement('span');
  testElement.style.position = 'absolute';
  testElement.style.left = '-9999px';
  testElement.style.fontSize = '12px';
  testElement.style.lineHeight = 'normal';
  testElement.textContent = testString;

  document.body.appendChild(testElement);

  const fontData = fonts.map((font) => {
    testElement.style.fontFamily = font;
    return {
      font,
      offsetWidth: testElement.offsetWidth,
      offsetHeight: testElement.offsetHeight,
    };
  });

  document.body.removeChild(testElement);

  return fontData;
};

export const checkSymbolProperties = () => {
  const symbolProperties = [
    'length',
    'name',
    'prototype',
    'for',
    'keyFor',
    'asyncIterator',
    'hasInstance',
    'isConcatSpreadable',
    'iterator',
    'match',
    'matchAll',
    'replace',
    'search',
    'species',
    'split',
    'toPrimitive',
    'toStringTag',
    'unscopables',
    'dispose',
  ];

  const supportedProperties = symbolProperties.filter((prop) => prop in Symbol);

  return supportedProperties;
};

export const checkCSSSupport = () => {
  const cssProperties = {
    'accent-color': ['initial'],
    'anchor-name': ['--tooltip'],
    'border-end-end-radius': ['initial'],
    color: ['light-dark'],
    fill: ['context-fill', 'context-stroke'],
    float: ['inline-start', 'inline-end'],
    'font-size': ['1cap', '1rcap'],
    'grid-template-rows': ['subgrid'],
    'paint-order': ['normal', 'stroke', 'markers', 'fill', 'revert'],
    stroke: ['context-fill', 'context-stroke'],
    'text-decoration': ['spelling-error'],
    'text-wrap': ['pretty'],
    'transform-box': ['stroke-box'],
  };

  const testElement = document.createElement('div');
  document.body.appendChild(testElement);

  const supportedProperties = {};

  for (const [property, values] of Object.entries(cssProperties)) {
    supportedProperties[property] = {};
    for (const value of values) {
      testElement.style[property] = value;
      supportedProperties[property][value] = testElement.style[property] === value;
    }
  }

  document.body.removeChild(testElement);

  return supportedProperties;
};

export const checkDRMSupport = () => {
  if (!navigator.requestMediaKeySystemAccess) {
    return { supported: false };
  }

  const config = [
    {
      initDataTypes: ['cenc'],
      audioCapabilities: [{ contentType: 'audio/mp4; codecs="mp4a.40.2"' }],
      videoCapabilities: [{ contentType: 'video/mp4; codecs="avc1.42E01E"' }],
    },
  ];

  const drmSystems = ['com.widevine.alpha', 'com.microsoft.playready', 'com.apple.fps.1_0', 'org.w3.clearkey'];

  return Promise.all(
    drmSystems.map((system) =>
      navigator
        .requestMediaKeySystemAccess(system, config)
        .then(() => ({ [system]: true }))
        .catch(() => ({ [system]: false }))
    )
  )
    .then((results) => {
      return {
        supported: true,
        systems: Object.assign({}, ...results),
      };
    })
    .catch(() => {
      return { supported: false };
    });
};

export const checkMediaDecodingCapabilities = async () => {
  if (!('mediaCapabilities' in navigator)) {
    return [];
  }

  const videoConfigurations = [
    {
      type: 'file',
      video: {
        contentType: 'video/mp4; codecs="avc1.42E01E"',
        width: 1920,
        height: 1080,
        bitrate: 2646242,
        framerate: 30,
      },
    },
    {
      type: 'file',
      video: {
        contentType: 'video/webm; codecs="vp9"',
        width: 1920,
        height: 1080,
        bitrate: 2646242,
        framerate: 30,
      },
    },
    {
      type: 'file',
      video: {
        contentType: 'video/ogg; codecs="theora"',
        width: 1280,
        height: 720,
        bitrate: 1500000,
        framerate: 30,
      },
    },
    {
      type: 'media-source',
      video: {
        contentType: 'video/mp4; codecs="avc1.42E01E"',
        width: 1920,
        height: 1080,
        bitrate: 2646242,
        framerate: 30,
      },
    },
    {
      type: 'media-source',
      video: {
        contentType: 'video/webm; codecs="vp9"',
        width: 1920,
        height: 1080,
        bitrate: 2646242,
        framerate: 30,
      },
    },
    {
      type: 'media-source',
      video: {
        contentType: 'video/ogg; codecs="theora"',
        width: 1280,
        height: 720,
        bitrate: 1500000,
        framerate: 30,
      },
    },
  ];

  const results = await Promise.all(
    videoConfigurations.map(async (config) => {
      try {
        const result = await navigator.mediaCapabilities.decodingInfo(config);
        return {
          contentType: config.video.contentType,
          powerEfficient: result.powerEfficient,
          smooth: result.smooth,
          supported: result.supported,
          keySystemAccess: result.keySystemAccess,
        };
      } catch (error) {
        return {
          contentType: config.video.contentType,
          powerEfficient: false,
          smooth: false,
          supported: false,
          keySystemAccess: null,
        };
      }
    })
  );

  return results;
};

export const checkBuiltInObjects = () => {
  const builtInObjects = [
    'AbortSignal',
    'Array',
    'ArrayBuffer',
    'Atomics',
    'BigInt',
    'Boolean',
    'Date',
    'Document',
    'Element',
    'Error',
    'Function',
    'GPU',
    'Intl',
    'JSON',
    'Map',
    'Math',
    'Navigation',
    'Navigator',
    'Number',
    'Object',
    'PerformanceNavigationTiming',
    'Promise',
    'Proxy',
    'RTCRtpReceiver',
    'ReadableStream',
    'Reflect',
    'RegExp',
    'SVGAElement',
    'Set',
    'ShadowRoot',
    'String',
    'Symbol',
    'WeakMap',
    'WeakSet',
    'WebAssembly',
    'WebSocketStream',
  ];

  const result = {};

  for (const objName of builtInObjects) {
    if (objName in window) {
      const obj = window[objName];
      const properties = Object.getOwnPropertyNames(obj);
      const methods = properties.filter((prop) => typeof obj[prop] === 'const');
      result[objName] = methods;
    }
  }

  return result;
};

export const checkColorSpaceSupport = () => {
  const colorSpaces = ['srgb', 'p3', 'rec2020'];
  const support = {};

  if ('matchMedia' in window) {
    colorSpaces.forEach((space) => {
      support[space] = window.matchMedia(`(color-gamut: ${space})`).matches;
    });
  } else {
    colorSpaces.forEach((space) => {
      support[space] = false;
    });
  }

  return support;
};

export const checkNavigatorProperties = () => {
  const result = [];

  for (const key in window.navigator) {
    result.push(key);
  }

  return result;
};
