import {
  checkBuiltInObjects,
  checkColorSpaceSupport,
  checkCSSSupport,
  checkDRMSupport,
  checkFileAPIs,
  checkFontRendering,
  checkMediaDecodingCapabilities,
  checkNavigatorProperties,
  checkSymbolProperties,
  checkWebKitAPIs,
  getComputedStyleProperties,
  getGlobalObjects,
  getMediaCapabilities,
  getMemoryInfo,
  getPerformanceData,
  getScreenResolution,
  getStorageQuota,
  getSystemInfo,
} from './additional.js';
import { getAudioContext } from './audio.js';
import { getRenderingParams } from './canvas.js';
import {
  AUDIO_PARAMS,
  EXTENDED_FONT_LIST,
  FONT_LIST,
  GL_PARAMS,
  NAVIGATOR_PARAMS,
  TEXTURE_FORMATS,
  WEBGL_FUNCTIONS,
} from './constants.js';

const getCiphers = () => {
  return fetch('https://tls.iphey.com/client_full_json')
    .then((res) => res.json())
    .then((res) =>
      res.cipher_suites.reduce((acc, res) => {
        if (res.name) {
          acc.push(res.name);
        }

        return acc;
      }, [])
    )
    .catch(() => null);
};

const getSupportedFormats = (device) => {
  try {
    const res = [];

    for (const format of TEXTURE_FORMATS) {
      try {
        let width = 1;
        let height = 1;

        if (format.startsWith('bc') || format.startsWith('e')) {
          width = 4;
          height = 4;
        }

        if (format.startsWith('astc')) {
          const match = format.match(/(\d+)x(\d+)/);
          if (match) {
            width = parseInt(match[1], 10);
            height = parseInt(match[2], 10);
          }
        }

        device.createTexture({
          size: [width, height, 1],
          format,
          usage:
            GPUTextureUsage.SAMPLED |
            GPUTextureUsage.OUTPUT_ATTACHMENT |
            GPUTextureUsage.STORAGE |
            GPUTextureUsage.COPY_SRC |
            GPUTextureUsage.COPY_DST,
        });

        res.push(format);
      } catch (err) {}
    }

    return res;
  } catch (err) {
    console.error(err);

    return null;
  }
};

const getWebgGPUAPI = async () => {
  const result = {};

  const canvas = document.createElement('canvas');
  const offscreen = !!canvas.transferControlToOffscreen;
  const offscreenCanvas = offscreen && canvas.transferControlToOffscreen();

  try {
    if (navigator.gpu) {
      result.gpu = true;
    }

    const adapter = await navigator.gpu.requestAdapter();

    if (adapter) {
      result.adapter = true;

      const compatAdapter = await navigator.gpu.requestAdapter({
        compatibilityMode: true,
      });

      result.compat = !!compatAdapter;

      const device = await adapter.requestDevice();
      if (device) {
        result.device = true;
        if (offscreenCanvas) {
          result.context = !!offscreenCanvas.getContext('webgpu');
        }
      }
    }
  } catch (err) {
    result.error = (err.message || err).toString();
  }

  try {
    const newOffscreenCanvas = new OffscreenCanvas(300, 150);
    const ctx = newOffscreenCanvas.getContext('2d');
    result.offscreen = true;
    result.twoD = !!ctx;
  } catch (err) {
    result.error = (err.message || err).toString();
  }

  return result;
};

const getWebGPUParams = async (options) => {
  try {
    const adapter = await navigator.gpu.requestAdapter(options);
    if (!adapter) {
      return null;
    }

    const features = Array.from(adapter.features);
    const [device, adapterInfo] = await Promise.all([
      adapter.requestDevice({ requiredFeatures: features }),
      adapter.requestAdapterInfo(),
    ]);
    const textureFormatCapabilities = getSupportedFormats(device);

    const limits = {};
    const { limits: limitsObject } = adapter;

    for (const key in limitsObject) {
      limits[key] = limitsObject[key];
    }

    const info = {};
    for (const key in adapterInfo) {
      info[key] = adapterInfo[key];
    }

    return {
      info,
      limits,
      features,
      textureFormatCapabilities,
      flags: {
        isCompatibilityMode: adapter.isCompatibilityMode,
        isFallbackAdapter: adapter.isFallbackAdapter,
      },
    };
  } catch (err) {
    console.error(err);

    return null;
  }
};

const getWebGPU = async () => {
  try {
    const result = {};

    result.api = await getWebgGPUAPI();

    if (!result.api.adapter) {
      return result;
    }

    const requestAdapterOptionsSets = {
      fallback: {
        powerPreference: 'low-power',
        forceFallbackAdapter: true,
      },
      highPerformance: { powerPreference: 'high-performance' },
    };

    result.supportedAdapters = {};

    const supportedAdaptersPromises = [];
    for (const [type, requestAdapterOptions] of Object.entries(requestAdapterOptionsSets)) {
      const promise = getWebGPUParams(requestAdapterOptions).then((params) => {
        result.supportedAdapters[type] = params;
      });
      supportedAdaptersPromises.push(promise);
    }

    await Promise.all(supportedAdaptersPromises);

    return result;
  } catch (err) {
    console.error(err);

    return null;
  }
};

const getParam = () =>
  new Promise(async (resolve) => {
    const getWebglOptions = () => {
      const loseContext = (ext) => {
        try {
          const t =
            ext.getExtension('WEBGL_lose_context') ||
            ext.getExtension('WEBKIT_WEBGL_lose_context') ||
            ext.getExtension('MOZ_WEBGL_lose_context');

          null != t && t.loseContext();
        } catch (err) {}
      };

      let e,
        n = 0;
      let result = {};

      window.WebGLRenderingContext && (e = 1);
      window.WebGL2RenderingContext && (n = 1);

      const isWebGLREnderContextExist = !!window.WebGL2RenderingContext;
      const supportedCanvas = (() => {
        let webglExts = ['webgl2', 'experimental-webgl2', 'webgl', 'experimental-webgl', 'moz-webgl', 'fake-webgl'];
        // let supportedExtensionsArr = [];
        let canvas = false;
        let index = 0;

        for (index in webglExts) {
          canvas = false;
          try {
            canvas = document.createElement('canvas').getContext(webglExts[index], {
              stencil: true,
            });
            //(r ? D(canvas) : savedCanvasContext = canvas, supportedExtensionsArr.push(webglExts[currentExtension]))
          } catch (err) {}
          if (![false, null].includes(canvas)) {
            break;
          }
        }
        return {
          gl: canvas,
          name: webglExts[index],
        };
      })();

      if (supportedCanvas) {
        //Supported functions
        const gl = supportedCanvas.gl;
        result.glCanvas = supportedCanvas.name;
        if (isWebGLREnderContextExist) {
          const supportedFunctions = [];

          for (const index in WEBGL_FUNCTIONS) {
            supportedFunctions.push({
              name: WEBGL_FUNCTIONS[index],
              supported: !!gl[WEBGL_FUNCTIONS[index]],
            });
          }

          result.supportedFunctions = supportedFunctions;
        }

        //Parameters
        const glParamValues = [];
        for (const i in GL_PARAMS) {
          let x;
          try {
            x = gl.getParameter(gl[GL_PARAMS[i]]);
          } catch (err) {}

          glParamValues.push({
            name: GL_PARAMS[i],
            value: x ? x : 'n/a',
          });
        }
        result.glParamValues = glParamValues;

        //Antialiasing
        result.antialiasing = false;
        try {
          result.antialiasing = gl.getContextAttributes().antialias;
        } catch (err) {}

        //render/vendor
        result.render = 'n/a';
        result.vendor = 'n/a';

        let ext = gl.getExtension('WEBGL_debug_renderer_info');
        if (ext !== null) {
          result.render = gl.getParameter(ext.UNMASKED_RENDERER_WEBGL);
          result.vendor = gl.getParameter(ext.UNMASKED_VENDOR_WEBGL);
          loseContext(ext);
        }

        //Texture Max Anisotropy Ext
        ext =
          gl.getExtension('EXT_texture_filter_anisotropic') ||
          gl.getExtension('WEBKIT_EXT_texture_filter_anisotropic') ||
          gl.getExtension('MOZ_EXT_texture_filter_anisotropic');

        result.textureMaxAnisotropyExt = 'n/a';
        if (ext) {
          result.textureMaxAnisotropyExt = gl.getParameter(ext.MAX_TEXTURE_MAX_ANISOTROPY_EXT);
          loseContext(ext);
        }

        //Shader precision format
        result.shaiderPrecisionFormat = 'n/a';
        try {
          let fragmentShader = gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_FLOAT);
          result.shaiderPrecisionFormat =
            (0 !== fragmentShader.precision ? 'highp/' : 'mediump/') +
            (0 !== (fragmentShader = gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_INT)).rangeMax
              ? 'highp'
              : 'lowp');
          loseContext(fragmentShader);
        } catch (err) {}

        //Extensions
        result.extensions = [];
        ext = gl.getSupportedExtensions();
        if (ext) {
          for (const i in ext) {
            result.extensions.push(ext[i]);
          }
          loseContext(ext);
        }
      }
      return result;
    };

    const getFontOptions = () => {
      const result = [];

      // a font will be compared against all the three default fonts.
      // and if it doesn't match all 3 then that font is not available.
      const baseFonts = ['monospace', 'sans-serif', 'serif'];

      const allFontList = FONT_LIST.concat(EXTENDED_FONT_LIST);

      // we use m or w because these two characters take up the maximum width.
      // And we use a LLi so that the same matching fonts can get separated
      const testString = 'mmmmmmmmmmlli';

      // we test using 72px font size, we may use any size. I guess larger the better.
      const testSize = '72px';

      const h = document.getElementsByTagName('body')[0];

      // div to load spans for the base fonts
      const baseFontsDiv = document.createElement('div');

      // div to load spans for the fonts to detect
      const fontsDiv = document.createElement('div');

      const defaultWidth = {};
      const defaultHeight = {};

      // creates a span where the fonts will be loaded
      const createSpan = () => {
        const span = document.createElement('span');
        /*
         * We need this css as in some weird browser this
         * span elements shows up for a microSec which creates a
         * bad user experience
         */
        span.style.position = 'absolute';
        span.style.left = '-9999px';
        span.style.fontSize = testSize;
        span.style.lineHeight = 'normal';
        span.innerHTML = testString;
        return span;
      };
      const createSpanWithFonts = (fontToDetect, baseFont) => {
        const s = createSpan();
        s.style.fontFamily = "'" + fontToDetect + "'," + baseFont;
        return s;
      };

      // creates spans for the base fonts and adds them to baseFontsDiv
      const initializeBaseFontsSpans = () => {
        const spans = [];
        for (let index = 0, length = baseFonts.length; index < length; index++) {
          const s = createSpan();
          s.style.fontFamily = baseFonts[index];
          baseFontsDiv.appendChild(s);
          spans.push(s);
        }
        return spans;
      };

      // creates spans for the fonts to detect and adds them to fontsDiv
      const initializeFontsSpans = () => {
        const spans = {};
        for (let i = 0, l = allFontList.length; i < l; i++) {
          const fontSpans = [];
          for (let j = 0, numDefaultFonts = baseFonts.length; j < numDefaultFonts; j++) {
            const s = createSpanWithFonts(allFontList[i], baseFonts[j]);
            fontsDiv.appendChild(s);
            fontSpans.push(s);
          }
          spans[allFontList[i]] = fontSpans; // Stores {fontName : [spans for that font]}
        }
        return spans;
      };

      // checks if a font is available
      const isFontAvailable = (fontSpans) => {
        let detected = false;
        for (let i = 0; i < baseFonts.length; i++) {
          detected =
            fontSpans[i].offsetWidth !== defaultWidth[baseFonts[i]] ||
            fontSpans[i].offsetHeight !== defaultHeight[baseFonts[i]];
          if (detected) {
            return detected;
          }
        }
        return detected;
      };

      // create spans for base fonts
      const baseFontsSpans = initializeBaseFontsSpans();

      // add the spans to the DOM
      h.appendChild(baseFontsDiv);

      // get the default width for the three base fonts
      for (let index = 0, length = baseFonts.length; index < length; index++) {
        defaultWidth[baseFonts[index]] = baseFontsSpans[index].offsetWidth; // width for the default font
        defaultHeight[baseFonts[index]] = baseFontsSpans[index].offsetHeight; // height for the default font
      }

      // create spans for fonts to detect
      const fontsSpans = initializeFontsSpans();

      // add all the spans to the DOM
      h.appendChild(fontsDiv);

      // check available fonts
      for (let i = 0, l = allFontList.length; i < l; i++) {
        if (isFontAvailable(fontsSpans[allFontList[i]])) {
          result.push(allFontList[i]);
        }
      }

      // remove spans from DOM
      h.removeChild(fontsDiv);
      h.removeChild(baseFontsDiv);

      return result;
    };

    const userAgent = () => {
      let result = '';
      if ('function' == typeof navigator.getUserAgent) {
        navigator.getUserAgent().then(function (e) {
          for (let t in e) {
            if ('boolean' == typeof e[t]) {
              result = e[t].toString();
            }
          }
        });
      } else {
        result = navigator.userAgent;
      }

      return result;
    };

    const getNavigatorParam = (retVal) => {
      retVal.navigator = {};
      let locRetVal = retVal.navigator;

      for (const index in NAVIGATOR_PARAMS) {
        locRetVal[NAVIGATOR_PARAMS[index]] = navigator[NAVIGATOR_PARAMS[index]];
      }

      locRetVal.userAgent = userAgent();
      locRetVal.languages = navigator.languages;
      locRetVal.language = navigator.language;

      retVal.mediaDevices = {
        deviceInfo: [],
      };

      if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        try {
          navigator.mediaDevices
            .enumerateDevices()
            .then(function (mediaDevicesInfo) {
              for (mediaDeviceInfo of mediaDevicesInfo) {
                retVal.mediaDevices.deviceInfo.push({
                  deviceId: mediaDeviceInfo.deviceId,
                  groupId: mediaDeviceInfo.groupId,
                  kind: mediaDeviceInfo.kind,
                  label: mediaDeviceInfo.label,
                });
              }
            })
            .catch(function () {});
        } catch (err) {}
      } else if (window.MediaStreamTrack && window.MediaStreamTrack.getSources) {
        try {
          retVal.mediaDevices = {};
          let locRetVal = retVal.mediaDevices;
          MediaStreamTrack.getSources(function (mediaDeviceInfo) {
            appendMediaDeviceInfo(mediaDeviceInfo);
          });
        } catch (err) {}
      }
      retVal.mediaDevices.videooutputs = retVal.mediaDevices.videooutputs === 0 ? 1 : retVal.mediaDevices.videooutputs;

      return locRetVal;
    };

    const getBrowserInfo = () => {
      const ua = navigator.userAgent;
      let tem;
      let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || ['Other'];

      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || ['Other'];
        return ['IE'];
      }

      if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return [tem.slice(1)[0].replace('OPR', 'Opera')];
      }

      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
      }

      return M;
    };

    const getWebrtcParam = (retVal) => {
      retVal.localIps = [];
      //retVal.remote_ips = [];
      let rtc_con = [];

      const analyzeRTCResources = (connections) => {
        if (!connections.length) {
          retVal.status = 'Fake webrtc detected: Webrtc test shows that you using some webrtc protections';
          return;
        }

        let ips = [];
        for (let connection of connections) {
          try {
            let connectionSplitData = connection.split(' ');
            if (connectionSplitData.length >= 8) {
              ips.push(connectionSplitData[4]);
            }
          } catch (h) {}
        }

        for (const ip of ips) {
          if (!ip.match(/^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})/)) {
            continue;
          }

          if (ip.match(/^(192\.168\.|169\.254\.|10\.|127\.|172\.(1[6-9]|2\d|3[01])\.)/)) {
            if (!retVal.localIps.includes(ip)) {
              retVal.localIps.push(ip);
            }
          } else {
            // if (!retVal.remote_ips.includes(ip))
            //     retVal.remote_ips.push(ip);
          }
        }
      };

      const c = {
        iceServers: [
          {
            urls: 'stun:stun.l.google.com:19302',
          },
        ],
      };

      const RTCPeerConnection =
        window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;

      let dataChannel;

      if (RTCPeerConnection) {
        dataChannel = new RTCPeerConnection(c);

        if (!('iceGatheringState' in dataChannel)) {
          retVal.status = 'Fake webrtc detected: Very old browser or webrtc protection is used';
          retVal;
        }

        dataChannel.onicecandidate = function (a) {
          if (a.candidate && a.candidate.candidate) {
            rtc_con.push(a.candidate.candidate);
          }

          if ('complete' === dataChannel.iceGatheringState) {
            analyzeRTCResources(rtc_con);
          }
        };

        dataChannel.createDataChannel('test');

        try {
          dataChannel.createOffer().then(function (offer) {
            return dataChannel.setLocalDescription(offer);
          });
        } catch (err) {
          if (err instanceof TypeError) {
            dataChannel.createOffer((a) => {
              dataChannel.setLocalDescription(a, (a) => {}, h);
            }, h);

            retVal.status = 'Fake webrtc detected: Very old browser or webrtc protection is used';
          }
        }
      }

      return retVal;
    };

    const getAudioParam = () => {
      const audio = AudioContext || webkitAudioContext;
      const audioObject = new audio();

      const result = {};

      for (const index in AUDIO_PARAMS) {
        let val = audioObject[AUDIO_PARAMS[index]];
        if (val !== undefined) {
          result[AUDIO_PARAMS[index]] = val;
        }
      }

      const destination = audioObject.destination;
      result.destination = {
        channelCount: destination.channelCount,
        channelCountMode: destination.channelCountMode,
        channelInterpretation: destination.channelInterpretation,
        maxChannelCount: destination.maxChannelCount,
        numberOfInputs: destination.numberOfInputs,
        numberOfOutputs: destination.numberOfOutputs,
      };

      return result;
    };

    const getPlatform = () => {
      let os = navigator.platform;
      const browserUserAgent = userAgent();

      if (!os.length) {
        if (browserUserAgent.indexOf('windows phone') >= 0) {
          os = 'Windows Phone';
        } else if (browserUserAgent.indexOf('win') >= 0) {
          os = 'Windows';
        } else if (browserUserAgent.indexOf('android') >= 0) {
          os = 'Android';
        } else if (browserUserAgent.indexOf('lin') >= 0) {
          os = 'Linux';
        } else if (browserUserAgent.indexOf('iphone') >= 0 || browserUserAgent.indexOf('ipad') >= 0) {
          os = 'iOS';
        } else if (browserUserAgent.indexOf('mac') >= 0) {
          os = 'Mac';
        } else {
          os = 'Other';
        }
      }

      if (os.toLowerCase().includes('linux')) {
        os = 'lin';
      }
      if (os.toLowerCase().includes('mac')) {
        os = 'mac';
      }
      if (os.toLowerCase() === 'windows' || os.toLowerCase().includes('win32')) {
        os = 'win';
      }
      if (
        os.toLowerCase().includes('android') ||
        os.toLowerCase().includes('armv') ||
        browserUserAgent.toLowerCase().includes('android')
      ) {
        os = 'android';
      }

      return os;
    };

    const result = {};
    //retVal.fingerprint = getWebGLFingerprint();

    result.browserType = getBrowserInfo()[0];
    if (result.browserType.toLowerCase() !== 'chrome') {
      return;
    }

    const [audioContext, ciphers, webGpu, storageQuota, DRMSupport, mediaDecodingCapabilities] = await Promise.all([
      getAudioContext(),
      getCiphers(),
      getWebGPU(),
      getStorageQuota(),
      checkDRMSupport(),
      checkMediaDecodingCapabilities(),
    ]);

    result.webRTC = {};

    getWebrtcParam(result.webRTC);
    getNavigatorParam(result);

    result.rendering = getRenderingParams();
    result.webglParams = getWebglOptions();
    result.fonts = getFontOptions();
    result.audio = getAudioParam();
    result.os = getPlatform();
    result.screen = getScreenResolution();
    result.navigator.resolution = result.screen.width + 'x' + result.screen.height;

    result.statsParams = {
      audioContext,
      storageQuota: storageQuota,
      globalObjectsCount: getGlobalObjects().length,
      mediaCapabilities: getMediaCapabilities(),
      performanceData: getPerformanceData(),
      memoryInfo: getMemoryInfo(),
      computedStyleProperties: getComputedStyleProperties(),
      systemInfo: getSystemInfo(),
      checks: {
        checkDRMSupport: DRMSupport,
        checkMediaDecodingCapabilities: mediaDecodingCapabilities,
        checkBuiltInObjects: checkBuiltInObjects(),
        checkColorSpaceSupport: checkColorSpaceSupport(),
        checkCSSSupport: checkCSSSupport(),
        checkFileAPIs: checkFileAPIs(),
        checkFontRendering: checkFontRendering(),
        checkNavigatorProperties: checkNavigatorProperties(),
        checkSymbolProperties: checkSymbolProperties(),
        checkWebKitAPIs: checkWebKitAPIs(),
      },
    };

    if (Array.isArray(ciphers) && ciphers.length) {
      result.ciphers = ciphers;
    }

    if (webGpu && webGpu.api.adapter) {
      result.webGpu = webGpu;
    }

    setTimeout(() => resolve(result), 4500);
  });

getParam().then((result) => {
  //p.fingerprint = md5(JSON.stringify(p));
  const hostname = window.location.hostname;
  let url = '/analytics/fingerprint-collection';
  if (!['localhost', '127.0.0.1'].includes(hostname)) {
    url = 'https://r.mixvisit.com/analytics/fingerprint-collection';
  }

  fetch(url, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ data: result }),
  });
});
