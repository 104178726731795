import { getSum, miniHash, hasProperty } from './helpers.js';

const getSnapshot = (arr, start, end) => {
  const collection = [];
  for (let i = start; i < end; i++) {
    collection.push(arr[i]);
  }

  return collection;
};

const getRenderedBuffer = (context) => {
  return new Promise((resolve) => {
    const analyser = context.createAnalyser();
    const oscillator = context.createOscillator();
    const dynamicsCompressor = context.createDynamicsCompressor();

    try {
      oscillator.type = 'triangle';
      oscillator.frequency.value = 10000;
      dynamicsCompressor.threshold.value = -50;
      dynamicsCompressor.knee.value = 40;
      dynamicsCompressor.attack.value = 0;
    } catch (err) {}

    oscillator.connect(dynamicsCompressor);
    dynamicsCompressor.connect(analyser);
    dynamicsCompressor.connect(context.destination);

    oscillator.start(0);
    context.startRendering();

    context.addEventListener('complete', (event) => {
      try {
        dynamicsCompressor.disconnect();
        oscillator.disconnect();

        const floatFrequencyData = new Float32Array(analyser.frequencyBinCount);
        analyser.getFloatFrequencyData(floatFrequencyData);

        const floatTimeDomainData = new Float32Array(analyser.fftSize);
        if (hasProperty(analyser, 'getFloatTimeDomainData')) {
          analyser.getFloatTimeDomainData(floatTimeDomainData);
        }

        return resolve({
          floatFrequencyData,
          floatTimeDomainData,
          buffer: event.renderedBuffer,
          compressorGainReduction: dynamicsCompressor.reduction.value || dynamicsCompressor.reduction,
        });
      } catch (err) {
        return resolve(null);
      }
    });
  });
};

export const getAudioContext = async () => {
  try {
    window.OfflineAudioContext = OfflineAudioContext || webkitOfflineAudioContext;
  } catch (err) {}

  if (!window.OfflineAudioContext) {
    return null;
  }

  const bufferLen = 5000;
  const context = new OfflineAudioContext(1, bufferLen, 44100);
  const analyser = context.createAnalyser();
  const oscillator = context.createOscillator();
  const dynamicsCompressor = context.createDynamicsCompressor();
  const biquadFilter = context.createBiquadFilter();

  const audioData = await getRenderedBuffer(new OfflineAudioContext(1, bufferLen, 44100));
  const { floatFrequencyData, floatTimeDomainData, buffer, compressorGainReduction } = audioData || {};

  const floatFrequencyDataSum = getSum(floatFrequencyData);
  const floatTimeDomainDataSum = getSum(floatTimeDomainData);

  const copy = new Float32Array(bufferLen);
  let bins = new Float32Array();
  if (buffer) {
    buffer.copyFromChannel(copy, 0);
    bins = buffer.getChannelData(0) || [];
  }

  const copyArr = [...copy];
  const binsArr = [...bins];

  const copySample = getSnapshot(copyArr, 4500, 4600);
  const binsSample = getSnapshot(binsArr, 4500, 4600);
  const sampleSum = getSum(getSnapshot(binsArr, 4500, bufferLen));

  const binsSampleValue = typeof binsSample[0] !== undefined ? miniHash(`${binsSample}`) : null;
  const copySampleValue = typeof copySample[0] !== undefined ? miniHash(`${copySample}`) : null;
  const totalUniqueSamples = new Set(binsArr).size;

  return {
    totalUniqueSamples,
    compressorGainReduction: compressorGainReduction || null,
    floatFrequencyDataSum: floatFrequencyDataSum || null,
    floatTimeDomainDataSum: floatTimeDomainDataSum || null,
    sampleSum: sampleSum || null,
    binsSample: binsSampleValue,
    copySample: copySampleValue,
    analyserNode: {
      channelCount: analyser.channelCount,
      channelCountMode: analyser.channelCountMode,
      channelInterpretation: analyser.channelInterpretation,
      fftSize: analyser.fftSize,
      frequencyBinCount: analyser.frequencyBinCount,
      maxDecibels: analyser.maxDecibels,
      minDecibels: analyser.minDecibels,
      numberOfInputs: analyser.numberOfInputs,
      numberOfOutputs: analyser.numberOfOutputs,
      smoothingTimeConstant: analyser.smoothingTimeConstant,
      context: {
        sampleRate: analyser.context.sampleRate,
        listener: {
          forwardX: {
            maxValue: analyser.context.listener.forwardX.maxValue,
          },
        },
      },
    },
    biquadFilterNode: {
      gain: {
        maxValue: biquadFilter.gain.maxValue,
      },
      frequency: {
        defaultValue: biquadFilter.frequency.defaultValue,
        maxValue: biquadFilter.frequency.maxValue,
      },
    },
    dynamicsCompressorNode: {
      attack: {
        defaultValue: dynamicsCompressor.attack.defaultValue,
      },
      knee: {
        defaultValue: dynamicsCompressor.knee.defaultValue,
        maxValue: dynamicsCompressor.knee.maxValue,
      },
      ratio: {
        defaultValue: dynamicsCompressor.ratio.defaultValue,
        maxValue: dynamicsCompressor.ratio.maxValue,
      },
      release: {
        defaultValue: dynamicsCompressor.release.defaultValue,
        maxValue: dynamicsCompressor.release.maxValue,
      },
      threshold: {
        defaultValue: dynamicsCompressor.threshold.defaultValue,
        minValue: dynamicsCompressor.threshold.minValue,
      },
    },
    oscillatorNode: {
      detune: {
        maxValue: oscillator.detune.maxValue,
        minValue: oscillator.detune.minValue,
      },
      frequency: {
        defaultValue: oscillator.frequency.defaultValue,
        maxValue: oscillator.frequency.maxValue,
        minValue: oscillator.frequency.minValue,
      },
    },
  };
};
