/**
 * Get type of given value as a lower case string.
 *
 * @param {*} value
 * @return {string}
 */
export const type = (value) => {
  const matches = Object.prototype.toString.call(value).match(/^\[object (\S+?)\]$/) || [];
  return matches[1]?.toLowerCase() || 'undefined';
};

export const TDef = {
  isString: (value) => type(value) === 'string',
  isNumber: (value) => type(value) === 'number',
  isNumberFinity: (value) => Number.isFinite(value),
  isNegativeInfinity: (value) => value === Number.NEGATIVE_INFINITY,
  isPositiveInfinity: (value) => value === Number.POSITIVE_INFINITY,
  isNaN: (value) => Number.isNaN(value),
  isObject: (value) => type(value) === 'object',
  isArray: (value) => type(value) === 'array',
  isBoolean: (value) => type(value) === 'boolean',
  isSymbol: (value) => type(value) === 'symbol',
  isUndefined: (value) => type(value) === 'undefined',
  isNull: (value) => type(value) === 'null',
  isDate: (value) => type(value) === 'date',
  isBigIng: (value) => type(value) === 'bigint',
  isMap: (value) => type(value) === 'map',
  isSet: (value) => type(value) === 'set',
  isWeakMap: (value) => type(value) === 'weakmap',
  isWeakSet: (value) => type(value) === 'weakset',
  isRegExp: (value) => type(value) === 'regexp',
  isFunc: (value) => ['asyncfunction', 'function'].includes(type(value)),
  isError: (value) => type(value) === 'error',
  isNil: (value) => TDef.isUndefined(value) || TDef.isNull(value),
};

/**
 * Generate a short hash from a given string.
 *
 * @param {string} str - The string to generate a hash for.
 * @return {string} A 10 character long hexadecimal hash.
 * @throws {TypeError} If the given value is not a string.
 */
export const miniHash = (str) => {
  if (!TDef.isString(str)) {
    throw new TypeError('Expected a string');
  }

  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash * 31 + char) % 0xffffffff;
  }

  return hash.toString(16).padStart(10, '0').slice(0, 10);
};

/**
 * Calculates the sum of the absolute values of all elements in an array.
 *
 * @param {number[]} [arr] The array to calculate the sum for.
 * @return {number} The sum of the absolute values of all elements in the array.
 */
export const getSum = (arr) => (!arr ? 0 : [...arr].reduce((acc, curr) => (acc += Math.abs(curr)), 0));

/**
 * Checks if a given object has the specified property.
 *
 * @param {Object} object - Object to search.
 * @param {string|number|symbol} key - Property name.
 * @return {boolean} true if the property exists, false otherwise.
 */
export const hasProperty = (object, key) => {
  if (TDef.isNil(object)) {
    return false;
  }

  if (Object.hasOwn(object, key)) {
    return true;
  }

  return key in object;
};

/**
 * Creates a canvas element with the specified width and height.
 *
 * @param {number} width The width of the canvas
 * @param {number} height The height of the canvas
 * @return {HTMLCanvasElement} The created canvas element
 */
export const createCanvas = (width, height) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  return canvas;
};

/**
 * Converts the given ImageData to a hexadecimal string.
 *
 * @param {ImageData} imageData The image data to convert
 * @return {string} The hexadecimal string representation of the image data
 */
export const imageDataToHex = (imageData) => {
  let hex = '';
  const { data } = imageData;

  for (let i = 0; i < data.length; i++) {
    const byteHex = data[i].toString(16).padStart(2, '0');
    hex += byteHex;
  }

  return hex;
};
